import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanMatchFn, Router, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { EnvironmentNames } from '../contants/environments';
import FundTypes from '../contants/fund-types';
import { Modals } from '../contants/modals';
import Urls from '../contants/urls';
import StorageHelper from '../helpers/storage.helper';
import { Fund } from '../models/fund';
import { FundService } from '../services/fund.service';
import { ModalsService } from '../services/modals.service';

@Injectable()
export class CanAccessGuard implements CanActivate {
  fund: Fund;

  // prettier ignore
  constructor(
    private fundService: FundService,
    private modalsService: ModalsService,
    private translateService: TranslateService,
    private router: Router
  ) {
    this.fundService.fund$.subscribe((fund: Fund) => {
      this.fund = fund;
    });
  }

  canActivate(_: ActivatedRouteSnapshot, __: RouterStateSnapshot) {
    const currentFund = this.fund || StorageHelper.getCurrentFund();
    if (!currentFund) {
      this.router.navigate([Urls.APP]);
      return false;
    }
    const currencyId: string =
      environment.environmentName !== EnvironmentNames.SWISS
        ? currentFund.primaryCurrencyId
        : currentFund.receiveGrantCurrency;
    const currencySymbol: string =
      environment.environmentName !== EnvironmentNames.SWISS
        ? currentFund.primaryCurrencySymbol
        : currentFund.receiveGrantCurrencySymbol;
    const noReceivingGrantsCurrency: boolean = [null, undefined, '0'].includes(currencyId);

    let popupMessage: string;

    if (noReceivingGrantsCurrency) popupMessage = 'DISTRIBUTION_WARNING_POPUP_NO_RECEIVING_CURRENCY';

    if (currentFund.fundType === FundTypes.CHARITY && popupMessage) {
      this.translateService
        .get(['DISTRIBUTION_WARNING_POPUP_TITLE', popupMessage], {
          currency: currencySymbol,
          email: environment.mailTo,
        })
        .subscribe((translated: string[]) => {
          this.modalsService.openModal(Modals.DYNAMIC_CONTENT_MODAL, {
            displayName: translated['DISTRIBUTION_WARNING_POPUP_TITLE'],
            infoMessage: translated[popupMessage],
          });
        });
      return false;
    }
    return true;
  }
}

export const canAccessGuard: CanMatchFn = () => {
  const fundService = inject(FundService);
  const modalService = inject(ModalsService);
  const translateService = inject(TranslateService);
  const router = inject(Router);

  return fundService.fund$.pipe(
    map((fund) => {
      const currentFund = fund || StorageHelper.getCurrentFund();

      if (!currentFund) {
        router.navigate([Urls.APP]);
        return false;
      }

      const currencyId: string =
        environment.environmentName !== EnvironmentNames.SWISS
          ? currentFund.primaryCurrencyId
          : currentFund.receiveGrantCurrency;
      const currencySymbol: string =
        environment.environmentName !== EnvironmentNames.SWISS
          ? currentFund.primaryCurrencySymbol
          : currentFund.receiveGrantCurrencySymbol;
      const noReceivingGrantsCurrency: boolean = [null, undefined, '0'].includes(currencyId);

      let popupMessage: string;

      if (noReceivingGrantsCurrency) popupMessage = 'DISTRIBUTION_WARNING_POPUP_NO_RECEIVING_CURRENCY';

      if (currentFund.fundType === FundTypes.CHARITY && popupMessage) {
        translateService
          .get(['DISTRIBUTION_WARNING_POPUP_TITLE', popupMessage], {
            currency: currencySymbol,
            email: environment.mailTo,
          })
          .subscribe((translated: string[]) => {
            modalService.openModal(Modals.DYNAMIC_CONTENT_MODAL, {
              displayName: translated['DISTRIBUTION_WARNING_POPUP_TITLE'],
              infoMessage: translated[popupMessage],
            });
          });
        return false;
      }
      return true;
    })
  );
};
