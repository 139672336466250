import { Observable } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  CanLoadFn,
  Data,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';

import { environment } from '../../../environments/environment';
import Urls from '../contants/urls';
import SharedMethodsHelpers from '../helpers/shared-methods.helpers';
import StorageHelper from '../helpers/storage.helper';
import { KeycloakService } from '../services/keycloak.service';

@Injectable()
export class AuthGuard implements CanActivate, CanLoad {
  constructor(private router: Router) {}

  // @ts-ignore
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | boolean {
    const redirectUrl = segments.map((segment) => segment.path).join('/');
    return this.canAccess(route.data, redirectUrl);
  }

  // @ts-ignore
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const redirectUrl = state.url;
    return this.canAccess(route.data, redirectUrl);
  }

  canAccess(routeData: Data, redirectUrl: string) {
    if (StorageHelper.getToken()) {
      if (!routeData) {
        return true;
      }
      if (routeData.environments && routeData.environments.indexOf(environment.environmentName) > -1) {
        return true;
      }
      if (routeData.roles && routeData.roles.indexOf(SharedMethodsHelpers.decodeToken().authorities[0]) === -1) {
        this.router.navigate([`${Urls.APP}/${Urls.CHANGE_PASSWORD}`]);
        return false;
      }
      return true;
    }
    this.router.navigate([Urls.AUTH], { queryParams: { returnUrl: redirectUrl } });
    return false;
  }
}

export const authGuard: CanLoadFn = (route: Route): boolean | UrlTree => {
  const keycloakService = inject(KeycloakService);
  const router = inject(Router);

  if (keycloakService.getIsLoggedIn()) {
    if (route.data?.environments.indexOf(environment.environmentName) < 0) return router.createUrlTree([Urls.APP]);
    // Add token roles check here
    return true;
  }
  StorageHelper.killSession();
  keycloakService.login();
  return false;
};
