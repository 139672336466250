<div class="grey-header">
  <div class="row header-container">
    <div class="col-xs-6">
      <a class="grey-link" [href]="contact" target="_blank">{{ 'HEADER_CONTACT' | translate | uppercase }}</a>
      <ng-container *ngIf="blog">
        <span class="mr-1 ml-1">|</span>
        <a class="grey-link" [href]="blog" target="_blank">{{ 'HEADER_BLOG' | translate | uppercase }}</a>
      </ng-container>
      <ng-container *ngIf="currentFund">
        <span class="mr-1 ml-1">|</span>
        <a class="grey-link" [routerLink]="urls.FAQS" target="_blank">
          <span>{{ 'HEADER_FAQS' | translate }}</span>
        </a>
      </ng-container>
      <ng-container *ngIf="globalConfig?.customLinkLabel">
        <span class="mr-1 ml-1">|</span>
        <a class="grey-link" [href]="globalConfig.customLinkUrl" target="_blank">
          <span [class.text-uppercase]="isSinngeber">{{ globalConfig.customLinkLabel | translate }}</span>
        </a>
      </ng-container>
    </div>
    <div class="col-xs-6 d-flex justify-content-end">
      <!-- Application Switcher -->
      <app-switcher *ngIf="tokenLinks?.length > 1" [tokenLinks]="tokenLinks"></app-switcher>
      <!--  -->
      <div *ngIf="currentUser" ngbDropdown class="nav-item nav-auto">
        <a href (click)="(false)" class="grey-link font-weight-bold mr-3" ngbDropdownToggle>
          <i class="fas fa-cog"></i
        ></a>
        <div ngbDropdownMenu>
          <button
            class="pointer"
            ngbDropdownItem
            *ngIf="currentUser?.funds?.length"
            (click)="goTo(urls.DONATE_BUTTONS)"
          >
            <span class="px-2">{{ 'HEADER_DONATE_BUTTONS' | translate }}</span>
          </button>
          <div *ngIf="currentUser?.funds?.length" class="dropdown-divider"></div>
          <button class="pointer px-2" ngbDropdownItem (click)="goTo(urls.CHANGE_PASSWORD)">
            <span class="px-2">{{ 'HEADER_CHANGE_PASSWORD' | translate }}</span>
          </button>
        </div>
      </div>
      <ul ngbNav #nav="ngbNav" [activeId]="2" class="nav-tabs justify-content-center">
        <li *ngIf="hasAdmin" class="nav-item nav-auto mx-2">
          <a
            class="grey-link"
            [ngClass]="isSinngeber ? 'text-uppercase' : 'font-weight-bold'"
            (click)="goTo(urls.ADMIN)"
            >{{ 'HEADER_ADMIN' | translate }}</a
          >
        </li>
        <li class="nav-item nav-auto mx-2" *ngIf="!onLoginPage">
          <a
            class="grey-link"
            [ngClass]="isSinngeber ? 'text-uppercase' : 'font-weight-bold'"
            (click)="loginLogout()"
            >{{ (currentUser || keycloakLoggedIn ? 'HEADER_LOG_OUT' : 'HEADER_LOG_IN') | translate }}</a
          >
        </li>
      </ul>
    </div>
  </div>
</div>

<div [ngClass]="isSinngeber ? 'sinngeber-header' : 'main-header'">
  <div class="red-container" *ngIf="!isMobile; else mobileView">
    <div class="header-items">
      <a class="img-wrap" (click)="goHome()">
        <img class="logo" [src]="logo" alt="logo-not-found" />
      </a>
    </div>
    <div class="header-items">
      <ng-container *ngFor="let nav of navLinks">
        <div
          *ngIf="isRecurringLink(nav.name) | async"
          class="nav-link-wrapper"
          [class.active]="isRouterLinkActive(nav.appLink)"
          [class.bg-grey]="isSinngeber"
          [hidden]="!nav.visibility"
          ngbDropdown
          #dropdown="ngbDropdown"
        >
          <!-- Private Recurring Contribution -->
          <!-- <ng-container *ngIf="nav.name === navLink.CONTRIBUTE; else popoverLink">
              <a class="nav-link" (mouseenter)="dropdown.open()" (click)="dropdown.toggle()">
                <img class="nav-item mb-1" [src]="'/assets/trust/' + nav.iconClass + '.png'" alt="img-not-found" />
                <p class="nav-text">{{ nav.title | translate }}</p>
              </a>
              <div ngbDropdownMenu (mouseenter)="dropdown.open()" (mouseleave)="dropdown.close()">
                <button class="pointer" ngbDropdownItem (click)="goTo(urls.DONATE)">
                  <span class="px-2">{{ 'HEADER_CONTRIBUTE_ONETIME' | translate }}</span>
                </button>
                <div class="dropdown-divider"></div>
                <button class="pointer px-2" ngbDropdownItem (click)="goTo(urls.DONATE, { isRecurring: true })">
                  <span class="px-2">{{ 'HEADER_CONTRIBUTE_RECURRING' | translate }}</span>
                </button>
                <div class="dropdown-divider"></div>
                <button class="pointer px-2" ngbDropdownItem (click)="goTo(urls.DONATE + '/' + urls.MANAGE_RECURRING)">
                  <span class="px-2">{{ 'HEADER_CONTRIBUTE_MANAGE_RECURRING_CONTRIBUTION' | translate }}</span>
                </button>
              </div>
            </ng-container> -->
          <!-- Private Recurring Distribution/Grant -->
          <ng-container *ngIf="isRecurringLink(nav.name) | async; else popoverLink">
            <a class="nav-link" (mouseenter)="dropdown.open()" (click)="onDropdownClick(nav.name)">
              <img class="nav-item mb-1" [src]="'/assets/trust/' + nav.iconClass + '.png'" alt="img-not-found" />
              <p class="nav-text">{{ nav.title | translate }}</p>
              <i class="fas fa-caret-down text-white position-absolute"></i>
            </a>
            <div ngbDropdownMenu (mouseenter)="dropdown.open()" (mouseleave)="dropdown.close()">
              <button
                class="pointer px-2"
                ngbDropdownItem
                (click)="
                  goTo(
                    urls.TRANSACTION +
                      '/' +
                      (nav.name === navLink.GRANT ? urls.GRANT : urls.DISTRIBUTE) +
                      '/' +
                      urls.MANAGE_RECURRING
                  )
                "
              >
                <span class="px-2">{{
                  (nav.name === navLink.DISTRIBUTE
                    ? 'HEADER_DISTRIBUTE_MANAGE_RECURRING_DISTRIBUTIONS'
                    : 'HEADER_GRANT_MANAGE_RECURRING_GRANTS'
                  ) | translate
                }}</span>
              </button>
            </div>
          </ng-container>
          <ng-template #popoverLink>
            <ng-container [ngTemplateOutlet]="headerItem" [ngTemplateOutletContext]="{ nav: nav }"></ng-container>
          </ng-template>
        </div>

        <div
          *ngIf="!(isRecurringLink(nav.name) | async)"
          class="nav-link-wrapper"
          [class.active]="isRouterLinkActive(nav.appLink)"
          [class.bg-grey]="isSinngeber"
          [popover]="nav.popoverMessage | translate"
          placement="bottom"
          triggers="mouseenter:mouseleave"
          [hidden]="!nav.visibility"
        >
          <ng-container [ngTemplateOutlet]="headerItem" [ngTemplateOutletContext]="{ nav: nav }"></ng-container>
        </div>
      </ng-container>

      <ng-template #headerItem let-nav="nav">
        <a class="nav-link" [routerLink]="nav.appLink" routerLinkActive>
          <img class="nav-item mb-1" [src]="'/assets/trust/' + nav.iconClass + '.png'" alt="img-not-found" />
          <p class="nav-text">{{ nav.title | translate }}</p>
        </a>
      </ng-template>
    </div>
  </div>

  <ng-template #mobileView>
    <nav class="mobile-nav">
      <div class="red-container my-0">
        <div class="header-items navbar-header">
          <a class="img-wrap" (click)="goHome()">
            <img class="logo" [src]="logo" />
          </a>
        </div>
        <div *ngIf="currentUser?.funds?.length && isMobileNavVisible">
          <button
            (click)="navbarCollapsed = !navbarCollapsed"
            type="button"
            class="navbar-toggle mr-4"
            [class.sinngeber-navbar-toggle]="isSinngeber"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-2"
          >
            <p class="icon-bar"></p>
            <p class="icon-bar"></p>
            <p class="icon-bar"></p>
          </button>
        </div>
      </div>
      <div [hidden]="navbarCollapsed" class="navbar-collapse" id="bs-example-navbar-collapse-2">
        <div class="navbar-collapse-wrapper" *ngFor="let nav of navLinks" [hidden]="!nav.visibility">
          <a
            (click)="handleMobileNavClick(nav)"
            class="nav-bar-item-collapse"
            [class.sinngeber-navbar-item]="isSinngeber"
          >
            {{ nav.title | translate }}
          </a>
          <!-- Private Recurring Contribution -->
          <!-- <ng-container *ngIf="nav.name === navLink.CONTRIBUTE && isSubNavOpen">
            <a
              (click)="goToPage(nav.appLink)"
              class="nav-bar-item-collapse sub-nav"
              [class.sinngeber-navbar-item]="isSinngeber"
            >
              {{ 'HEADER_CONTRIBUTE_ONETIME' | translate }}
            </a>
            <a
              (click)="goToPage(nav.appLink, { isRecurring: true })"
              class="nav-bar-item-collapse sub-nav"
              [class.sinngeber-navbar-item]="isSinngeber"
            >
              {{ 'HEADER_CONTRIBUTE_RECURRING' | translate }}
            </a>
            <a
              (click)="goToPage(nav.appLink + '/' + urls.MANAGE_RECURRING)"
              class="nav-bar-item-collapse sub-nav"
              [class.sinngeber-navbar-item]="isSinngeber"
            >
              {{ 'HEADER_CONTRIBUTE_MANAGE_RECURRING' | translate }}
            </a>
          </ng-container> -->

          <!--  -->
          <ng-container *ngIf="(isRecurringLink(nav.name) | async) && isSubNavOpen">
            <a
              (click)="
                goToPage(
                  nav.appLink,
                  {},
                  nav.name === navLink.GRANT ? 'PAGE_TITLE_GRANT_DAF' : 'PAGE_TITLE_GRANT_CHARITY'
                )
              "
              class="nav-bar-item-collapse sub-nav"
              [class.sinngeber-navbar-item]="isSinngeber"
            >
              {{
                (nav.name === navLink.GRANT ? 'HEADER_GRANT_CREATE_GRANT' : 'HEADER_DISTRIBUTE_CREATE_DISTRIBUTION')
                  | translate
              }}
            </a>
            <a
              (click)="goToPage(nav.appLink + '/' + urls.MANAGE_RECURRING)"
              class="nav-bar-item-collapse sub-nav"
              [class.sinngeber-navbar-item]="isSinngeber"
            >
              {{
                (nav.name === navLink.GRANT
                  ? 'HEADER_GRANT_MANAGE_RECURRING_GRANTS'
                  : 'HEADER_DISTRIBUTE_MANAGE_RECURRING_DISTRIBUTIONS'
                ) | translate
              }}
            </a>
          </ng-container>
        </div>
      </div>
    </nav>
  </ng-template>
</div>
