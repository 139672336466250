import { NumberFormatSymbolOption, digitGroupingFormats } from '../../pages/admin-welcome/global-config/number-formats';

export function applyNumberFormat(
  value: string,
  decimalSymbol: NumberFormatSymbolOption,
  digitGroupingSymbol: NumberFormatSymbolOption,
  digitGrouping: string
): string {
  if (!value) return;

  const removeSymbol = (input: string): { amount: string; currencySymbol: string } => {
    const currencySymbol = input.match(/^\D*/)?.[0] ?? '';
    const amount = input.replace(/^\D*/, '');
    return { amount, currencySymbol };
  };

  const { currencySymbol, amount } = removeSymbol(value);

  const parsedValue = parseFloat(amount.replace(/,/g, '')).toFixed(2);
  const baseFormat = Number(parsedValue).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  let formattedValue = baseFormat;

  switch (digitGroupingFormats.indexOf(digitGrouping)) {
    case 0:
      formattedValue = Number(parsedValue).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: false,
      });
      break;
    case 1:
      formattedValue = baseFormat;
      break;
    case 2:
      formattedValue = baseFormat.replace(/,/g, (match, offset, original) =>
        original.indexOf(',', offset + 1) !== -1 ? '' : match
      );
      break;
    case 3:
      formattedValue = Number(parsedValue).toLocaleString('en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      break;
  }
  // Set digit grouping symbol
  formattedValue = formattedValue.replace(/,/g, digitGroupingSymbol.value);

  // Set decimal symbol
  formattedValue = replaceCharAtIndex(formattedValue, formattedValue.length - 3, decimalSymbol.value);

  return currencySymbol ? currencySymbol + formattedValue : formattedValue;
}

export function replaceCharAtIndex(inputString: string, index: number, replacementChar: string): string {
  return inputString.slice(0, index) + replacementChar + inputString.slice(index + 1);
}
