import { NullValidationHandler, OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { AccessRoles, IdentityClaims } from '../models/keycloak';

@Injectable({
  providedIn: 'root',
})
export class KeycloakService {
  constructor(private oauthService: OAuthService) {}
  completedInitialization$ = new BehaviorSubject<boolean>(false);

  init(): Observable<boolean> {
    this.oauthService.tokenValidationHandler = new NullValidationHandler();

    this.oauthService.events
      .pipe(first(({ type }) => type.includes('session_terminated')))
      .subscribe(() => this.logout());

    return from(this.oauthService.loadDiscoveryDocumentAndTryLogin()).pipe(
      filter((success) => success && this.oauthService.hasValidAccessToken()),
      tap(() => {
        this.oauthService.setupAutomaticSilentRefresh();
        this.completedInitialization$.next(true);
      })
    );
  }

  login(): void {
    this.oauthService.initLoginFlow();
  }

  logout(): void {
    this.oauthService.stopAutomaticRefresh();
    this.oauthService.logOut({ client_id: this.oauthService.clientId });
  }

  getAccessToken(): string {
    return this.oauthService.getAccessToken();
  }

  getIsLoggedIn(): boolean {
    return this.oauthService.hasValidIdToken() && this.oauthService.hasValidAccessToken();
  }

  getUsername(): string {
    let claims = this.oauthService.getIdentityClaims() as IdentityClaims;
    return claims ? claims.name : '';
  }

  getIsAdmin(): boolean {
    const token = this.oauthService.getAccessToken();
    let isAdmin = false;

    if (token) {
      const payload = token.split('.')[1];
      const payloadDecodedJson = atob(payload);
      const payloadDecoded = JSON.parse(payloadDecodedJson);
      isAdmin = payloadDecoded.realm_access.roles.indexOf(AccessRoles.ADMIN) !== -1;
    }

    return isAdmin;
  }
}
