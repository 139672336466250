import { zip, Subscription, Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';

import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';

import { environment } from '../../../environments/environment';
import { EnvironmentNames } from '../../_core/contants/environments';
import FundTypes from '../../_core/contants/fund-types';
import { HEADER_NAV_LINKS, NAV_LINK } from '../../_core/contants/Tabs';
import Urls from '../../_core/contants/urls';
import { AccessLevels } from '../../_core/contants/user-role';
import SharedMethodsHelpers from '../../_core/helpers/shared-methods.helpers';
import { FeatureFlagKeys } from '../../_core/models/FeatureFlags';
import { Fund } from '../../_core/models/fund';
import { AccessRoles } from '../../_core/models/keycloak';
import { User } from '../../_core/models/user';
import { FeatureFlagService } from '../../_core/services/feature-flag.service';
import { FundService } from '../../_core/services/fund.service';
import { GlobalConfigService } from '../../_core/services/global-config.service';
import { KeycloakService } from '../../_core/services/keycloak.service';
import { RolesService } from '../../_core/services/roles.service';
import { TitleService } from '../../_core/services/title.service';
import { UserService } from '../../_core/services/user.service';
import { ConfigSetting } from '../../pages/admin-welcome/global-config/global-config.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  navLinks = HEADER_NAV_LINKS;
  navLink = NAV_LINK;
  urls = Urls;
  subscriptions: Subscription = new Subscription();
  currentFund: Fund;
  currentUser: User;
  contact: SafeUrl;
  globalConfig: ConfigSetting;
  logo: string;
  blog: SafeUrl;
  hasAdmin: boolean;
  navbarCollapsed = true;
  isMobile: boolean;
  multiCurrency: boolean;
  onAdminPage: boolean;
  onLoginPage: boolean;
  isSinngeber: boolean;
  isSubNavOpen: boolean;
  keycloakLoggedIn = this.keycloakService.getIsLoggedIn();
  tokenLinks = SharedMethodsHelpers.decodeToken(this.keycloakService.getAccessToken()).links;
  isMobileNavVisible = true;

  hasRecurringFlag$ = this.featureFlagService.hasFeatureFlag(FeatureFlagKeys.RecurringContribution);
  hasRecurringGrantsFlag$ = this.featureFlagService.hasFeatureFlag(FeatureFlagKeys.RecurringGrants);
  hasRecurringDistributionsFlag$ = this.featureFlagService.hasFeatureFlag(FeatureFlagKeys.RecurringDistributions);

  constructor(
    private router: Router,
    private fundService: FundService,
    private userService: UserService,
    private breakpointObserver: BreakpointObserver,
    private globalConfigService: GlobalConfigService,
    private sanitizer: DomSanitizer,
    private featureFlagService: FeatureFlagService,
    private keycloakService: KeycloakService,
    private rolesService: RolesService,
    private titleService: TitleService
  ) {
    this.subscriptions.add(
      this.fundService.fund$.subscribe((fund: Fund) => {
        this.currentFund = fund;
        this.computeLinksVisibility();
      })
    );

    this.subscriptions.add(
      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((value: NavigationEnd) => {
        this.onAdminPage = value.urlAfterRedirects.indexOf(Urls.ADMIN) >= 0;
        this.onLoginPage = value.urlAfterRedirects.indexOf(Urls.LOGIN) >= 0;
        this.computeLinksVisibility();
      })
    );

    this.subscriptions.add(
      this.breakpointObserver.observe(['(max-width: 1100px)']).subscribe((result) => {
        this.isMobile = result.matches;
      })
    );

    this.subscriptions.add(
      this.userService.currentUser$.subscribe((user) => {
        this.currentUser = user;
      })
    );

    this.subscriptions.add(
      this.globalConfigService.globalConfig$.subscribe((globalConfig) => {
        this.globalConfig = globalConfig;
      })
    );
  }

  ngOnInit(): void {
    this.isSinngeber = environment.environmentName === EnvironmentNames.SINNGEBER;
    this.onAdminPage = this.router.url.indexOf(Urls.ADMIN) >= 0;
    this.hasAdmin = this.rolesService.hasRole(AccessRoles.ADMIN);
    this.setEnvironmentVariables();
    this.computeLinksVisibility();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  handleMobileNavClick(nav: any) {
    zip(this.hasRecurringGrantsFlag$, this.hasRecurringDistributionsFlag$)
      .pipe(first())
      .subscribe(([hasRecurringGrants, hasRecurringDistributions]) =>
        (nav.name === this.navLink.GRANT && hasRecurringGrants) ||
        (nav.name === this.navLink.DISTRIBUTE && hasRecurringDistributions)
          ? this.toggleSubNav()
          : this.goToPage(nav.appLink)
      );
  }

  isRouterLinkActive(appLink: string): boolean {
    return this.router.url.includes(`/${appLink}`);
  }

  setEnvironmentVariables(): void {
    this.logo = environment.logo;
    this.multiCurrency = environment.multiCurrency;
    this.contact = this.isSinngeber
      ? 'mailTo: ' + environment.contact
      : this.sanitizer.bypassSecurityTrustUrl(environment.contact);
    environment.blog && (this.blog = this.sanitizer.bypassSecurityTrustUrl(environment.blog));
  }

  computeLinksVisibility(): void {
    if (!this.currentFund || !this.currentUser || this.currentUser.resetPass || this.onAdminPage) {
      this.navLinks.forEach((link) => {
        link.visibility = false;
      });
      this.isMobileNavVisible = false;
      return;
    }
    this.navLinks.forEach((link) => {
      link.visibility = true;
    });
    this.isMobileNavVisible = true;

    if (this.currentFund.fundType === FundTypes.CHARITY) {
      let viewAccessLinks = [NAV_LINK.HOME, NAV_LINK.CONTRIBUTE, NAV_LINK.ACTIVITY];
      const FundExpired = this.fundService.isFundExpired(this.currentFund);
      if (FundExpired || this.currentFund.parentFund?.expired) {
        viewAccessLinks.pop();
        this.navLinks.forEach((link) => {
          if (!viewAccessLinks.includes(link.name)) link.visibility = false;
        });
        return;
      } else if (this.currentFund?.contactRoleAccessLevel === AccessLevels.VIEW) {
        this.navLinks.forEach((link) => {
          if (!viewAccessLinks.includes(link.name)) link.visibility = false;
        });
        return;
      }
    }

    this.navLinks.forEach((link) => {
      switch (link.name) {
        case NAV_LINK.CONTRIBUTE:
          link.visibility = true;
          break;
        case NAV_LINK.FX_CONVERSION:
          link.visibility = this.multiCurrency && this.currentFund.contactRoleAccessLevel === AccessLevels.FULL;
          break;
        case NAV_LINK.TRANSFER:
          if (this.currentFund.fundType === FundTypes.PROJECT) {
            link.visibility = !!this.currentFund.parentFund;
          } else if (this.currentFund.fundType === FundTypes.CHARITY) {
            link.visibility = this.currentFund.projects.length > 0;
          } else {
            link.visibility =
              this.currentFund.fundType === FundTypes.DAF &&
              this.currentFund.contactRoleAccessLevel === AccessLevels.FULL;
          }
          break;
        case NAV_LINK.DISTRIBUTE:
          link.visibility =
            this.currentFund.fundType === FundTypes.CHARITY &&
            this.currentFund.contactRoleAccessLevel === AccessLevels.FULL;
          break;
        case NAV_LINK.GRANT:
          link.visibility =
            this.currentFund.fundType !== FundTypes.CHARITY &&
            this.currentFund.fundType !== FundTypes.PROJECT &&
            this.currentFund.contactRoleAccessLevel === AccessLevels.FULL;
          break;
        default:
          link.visibility = true;
          break;
      }
    });
  }

  toggleSubNav(): void {
    this.isSubNavOpen = !this.isSubNavOpen;
  }

  goToPage(appLink: string, queryParams = {}, pageTitle: string = null): void {
    this.navbarCollapsed = true;
    this.isSubNavOpen = false;
    this.router.navigate([`${Urls.APP}/${appLink}`], { queryParams });
    pageTitle && this.titleService.titleChanged(pageTitle);
  }

  goHome(): void {
    if (!this.currentUser) {
      this.router.navigate([Urls.AUTH]);
      return;
    }
    if (this.currentUser.resetPass) return;
    if (this.currentUser) {
      this.router.navigate([`${Urls.APP}/${Urls.HOME}`]);
    }
  }

  goTo(url: string, queryParams = {}): void {
    this.router.navigate([`${Urls.APP}/${url}`], { queryParams });
  }

  loginLogout(): void {
    if (this.userService.isLoggedIn() || this.keycloakLoggedIn) {
      this.userService.logout();
    } else {
      this.router.navigate([Urls.AUTH]);
    }
  }

  isRecurringLink(name: NAV_LINK): Observable<boolean> {
    return (
      (name === NAV_LINK.DISTRIBUTE && this.hasRecurringDistributionsFlag$) ||
      (name === NAV_LINK.GRANT && this.hasRecurringGrantsFlag$)
    );
  }

  onDropdownClick(name: NAV_LINK): void {
    name === NAV_LINK.DISTRIBUTE && this.router.navigate([`${Urls.APP}/${Urls.TRANSACTION}/${Urls.DISTRIBUTE}`]);
    name === NAV_LINK.GRANT && this.router.navigate([`${Urls.APP}/${Urls.TRANSACTION}/${Urls.GRANT}`]);
  }
}
