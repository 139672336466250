<div>
  <h4>Other Asset Balances</h4>
  <div class="wrap">
    <div class="closing-balance">
      {{ otherAssetsBalances | number : '1.2-2' | numberFormat }} {{ selectedCurrencySymbol }}
    </div>
    <div>
      <span class="required">*</span> This is the total amount from all your {{ selectedCurrencySymbol }} other assets
    </div>
  </div>
</div>

<!--Table Section-->
<div class="row mt-5">
  <div class="col-sm-12">
    <h5>Other Asset Summary</h5>
    <div class="table mt-1">
      <table class="table">
        <thead>
          <th class="table-text">Asset Name</th>
          <th class="text-right">Amount</th>
          <th class="text-right">Details</th>
        </thead>
        <tbody>
          <tr *ngFor="let asset of otherAssetBalanceList | sortBy : 'desc' : 'fundCash'">
            <td>
              <a class="table-link" (click)="goToActivity(asset)">{{ asset.assetName }}</a>
            </td>
            <td class="text-right table-text-black">
              {{ asset.closingBalance | number : '1.2-2' | numberFormat }} {{ asset.currencySymbol }}
            </td>
            <td class="fixed-width">
              <button class="btn sm-button main-background" role="button" (click)="goToActivity(asset)">Select</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
